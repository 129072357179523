<template>
    <div>
        <h1>On Time</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Chart v-bind:results="state.results[state.report]"></Chart>
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store.js";
    import Options from "./delivery_ontime_opt";
    import Chart from "../utils/Chart";
    import Results from "./utils/Results";
    import DateHelpers from "@/store/DateHelpers";
    export default {
        name: 'delivery_ontime',
        components: {Results, Chart, Options},
        data() {
            return {
                state: store.state,
                optionFields: {
                    shipStartDate: {val: DateHelpers.getElevenMonthsAgo(), qsField: '[dates][actual_ship_from]'},
                    shipEndDate: {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][cdi_location_id]'},
                    interval: {val: 'M', qsField: '[interval]'},
                    type: {val: 'delivery', qsField: '[type]'},
                    carrierId: {val: '', qsField: '[numbers][shipping_co_id]'},
                    hideInvalid: {val: 1, qsField: '[hide_invalid]'},
                    showCurbCount: {val: 0, qsField: '[include_curb_count]'},
                    state: {val: 0, qsField: '[strings][sa.state]', defaultVal: 0},
                    chart: {val: 1, qsField: '[chart]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['On Time']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>