<template>
    <div class="panel panel-default toggle_container">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Start Date</label>
                <DatePicker v-model="state.optionFields.shipStartDate.val" :input-attributes="{ class: 'form-control'}"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship End Date</label>
                <DatePicker v-model="state.optionFields.shipEndDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Interval</label>
                <select class='form-control' v-model="state.optionFields.interval.val">
                    <option value="D">Day</option>
                    <option value="W">Week</option>
                    <option value="M" selected>Month</option>
                    <option value="Y">Year</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Type</label>
                <select class='form-control' v-model="state.optionFields.type.val">
                    <option value="ship">Ship</option>
                    <option value="delivery" selected>Delivery</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Carrier</label>
                <Typeahead cdi_type="shipping_co" v-bind:ID.sync="state.optionFields.carrierId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox  :true-value="1" :false-value=false  v-model="state.optionFields.hideInvalid.val"/> Hide Invalid</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox v-model="state.optionFields.showCurbCount.val" /> Show curb count</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>State</label>
                <select v-model="state.optionFields.state.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                </select>
            </div>

            <div class="col-xs-12"><button type="button" class="btn btn-default btn-sm" @click="toggleMoreFilters">{{ (toggleHide) ? 'More' : 'Less' }} Filters</button></div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js";
    import cdiLocationCache from "@/cache/cdiLocation.cache.js";
    import stateCache from "@/cache/state.cache.js";
    import DatePicker from "../utils/DatePicker";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";


    export default {
        name: 'Options',
        components: {DatePicker, Typeahead },
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                    stateCache
                },
                toggleHide: true
            }
        },
        methods: {
            toggleMoreFilters: function() {
                this.toggleHide = !this.toggleHide;
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>